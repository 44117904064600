.sidebar-container {
    transition: 0.3s ease-in-out;
    z-index: 998;
    width: 80px;
    /* width: 220px; */
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.05) 0px 7px 29px 0px;
}

.sidebar-container:hover {
    width: 250px;
}

.static {
    width: 250px;
}

.sidebar-container .version {
    width: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-right: 5px;
}

.sidebar-container:hover .version {
    width: fit-content;
}

.static .version {
    width: fit-content;
}

.sidebar-container .help > div {
    overflow: auto;
    width: 120px;
    display: none;
}

.sidebar-container .help {
    width: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.sidebar-container:hover .help {
    width: 120px;
    opacity: 1;
    visibility: visible;
    position: static;
}

.static .help {
    width: 120px;
    opacity: 1;
    visibility: visible;
    position: static;
}

.sidebar-container:hover .help > div {
    display: block;
}

.static .help > div {
    display: block;
}

.sidebar-container:not(.static):hover {
    position: absolute;
    z-index: 998 !important;
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.sidebar-container:not(.static) .sidebar-item-title {
    width: 0;
}

.sidebar-container:hover .sidebar-item-title {
    width: 180px;
}

.sidebar-item-title {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
}

.sidebar-container .sidebar-item-text {
    transition: width 0.3s ease-in-out;
}

.sidebar-container:not(.static) .sidebar-item-text {
    width: 25px;
}

.sidebar-container:hover .sidebar-item-text {
    width: 180px; /* to make 180 when showing rh menu*/
}

.static .sidebar-item-text {
    width: 180px; /* to make 180 when showing rh menu*/
}

.sidebar-container:not(.static) .expand-icon {
    width: 0;
}

.sidebar-container:hover .expand-icon {
    width: auto;
}

.expand-icon {
    overflow: hidden;
    transition: 0.3s ease-in-out;
}

.logo-name {
    overflow: hidden;
    width: fit-content;
    /* transition: 0.3s ease-in-out; */
}

.sidebar-container:not(.static) .logo-name {
    width: 0;
}

.sidebar-container:hover .logo-name {
    width: fit-content;
}

.static .logo-picture {
    display: none;
}

.sidebar-container:not(.static) .logo-picture {
    display: block;
}

.sidebar-container:not(.static):hover .logo-picture {
    display: none;
}

.logo-picture {
    overflow: hidden;
    transition: 0.3s ease-in-out;
    display: none;
}
