.new-dept-card-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    padding-top: 25px;
    padding-bottom: 0;
}
.employees-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.employees-section h1 {
    // margin-left: 15px;
    margin-bottom: 7px;
}
.new-dept-search-bar {
    display: flex;
    flex-direction: row;
    background-color: #edeff5;
    align-items: center;
    justify-content: space-between;
    padding: 5px 32px;
}
.new-dept-list-of-employees-dapartments {
    padding: 5px 25px;

    // display: flex;
    // flex-direction: column;
    // width: 100%;
    // max-height: 1000px;
    // overflow: auto;
    // scrollbar-width: thin;
    // margin-bottom: 50px;
    // background-color: red;
}
.new-dept-list-of-employees-dapartments .employee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #edeff5;
    margin: 0;
}
.new-dept-list-of-employees-dapartments .employee:last-child {
    border-bottom: 0px solid #edeff5;
}

.new-dept-list-of-employees-dapartments .employee .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px 0;
}
.new-dept-list-of-employees-dapartments .employee .information img {
    margin-right: 15px;
}
.new-dept-list-of-employees-dapartments .employee .employee-selection {
}
