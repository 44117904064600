.dashboard-container {
    display: flex;
    flex-direction: row;
    background-color: #f7f8fa;
    // height: calc(100vh - 100px);
    overflow: hidden;
    width: 100vw;
}
.dashboard-container .body-container {
    padding: 10px;
    justify-self: flex-end;
}
.dashboard-container .switch-pages {
    width: 20px;
    // opacity: 0;
    // background-color: rgba(255, 255, 255, 0);
}
.dashboard-container .switch-pages:hover {
    width: 120px;
    background-color: rgba(255, 255, 255, 0.8);

    // opacity: 0.8;
}

// .dashboard-container {
//     display: flex;
//     background-color: #f7f8fa;
// }

// .dashboard-container .content-container {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     margin: 10px;
//     padding-left: 20px;
//     padding-right: 20px;
// }
// .dashboard-container .body-container {
//     display: flex;
//     flex-direction: column;
//     flex: 1;
//     padding-top: 80px;
//     // margin-top: 10px;
//     margin-left: auto;
//     margin-right: auto;
//     overflow-x: scroll;
//     width: 100%;

//     @media screen and (min-width: 768px) {
//         //margin: 30px 20px;
//         max-width: calc(100vw - 200px);
//     }
//     @media screen and (max-width: 768px) {
//         max-width: calc(100vw - 60px);
//         //margin: 30px 20px;
//     }
// }
// .dashboard-container .topbar-container {
//     margin-left: auto;
//     margin-right: auto;
//     width: 100%;

//     @media screen and (min-width: 768px) {
//         //margin: 30px 20px;
//         max-width: calc(100vw - 200px);
//     }
//     @media screen and (max-width: 768px) {
//         max-width: calc(100vw - 60px);
//         //margin: 30px 20px;
//     }
// }
// .dashboard-container .footer-container {
//     height: 2vh;
//     width: 100%;
// }
