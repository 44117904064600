.integration-card-container {
    display: flex;
    flex-direction: column;
    width: 250px;
    border-radius: 10px;
}
.integration-card-container .integration-logo {
    padding: 20px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.integration-card-container .integration-body {
    padding: 30px;
    padding-top: 20px;
    border-radius: 0 0 10px 10px;
}

.integration-card-container .integration-body #title {
    font-size: 20px;
    font-weight: bold;
}

.integration-card-container .integration-body #description {
    font-size: 14px;
    margin-bottom: 15px;
}
.integration-card-container .integration-body button {
    justify-content: center;
}
@media (max-width: 1300px) {
    .integration-card-container {
        width: 100%;
    }
}
